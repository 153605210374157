/* eslint-disable */
document.addEventListener('DOMContentLoaded', function () {
  const sw = new Swiper('.swiper-container', {
    autoplay: {
      stopOnLastSlide: true
    },
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 25,
    loop: true,
    observer: true,
    loopedSlides: 3,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    }
  })
})

// $(function() {
//   $('#myModal').addClass('in')
//   $('body').addClass('modal-open')
//   $('body').append('<div class="modal-backdrop fade show"></div>')

//   $('.modal-backdrop, .modal-close').on({
//     'click': function(){
//       $("#myModal").removeClass('in')
//       $('body').removeClass('modal-open')
//       $('.modal-backdrop').remove()
//     }
//   })
// })